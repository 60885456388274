import React from 'react';
import VideoUploader from './VideoUploader';
import './App.css'; // Import the global CSS file
import Logger from './Logger';

function App() {
  return (
    <div className="App">
      <VideoUploader />
      <Logger />
    </div>
  );
}

export default App;
