// src/Logger.js
import React, { useState, useEffect } from 'react';

const Logger = () => {
  const [logs, setLogs] = useState([]);

  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  useEffect(() => {
    const handleLog = (message) => {
      addLog(message);
    };

    // Overriding console.log
    const originalLog = console.log;
    console.log = (...args) => {
      originalLog(...args);
      handleLog(args.join(' '));
    };

    // Overriding console.error
    const originalError = console.error;
    console.error = (...args) => {
      originalError(...args);
      handleLog(args.join(' '));
    };

    return () => {
      console.log = originalLog;
      console.error = originalError;
    };
  }, []);

  return (
    <div style={{ padding: '10px', backgroundColor: '#f0f0f0', height: '200px', overflowY: 'scroll' }}>
      <h4>Logs</h4>
      <ul>
        {logs.map((log, index) => (
          <li key={index}>{log}</li>
        ))}
      </ul>
    </div>
  );
};

export default Logger;
